import { Suspense, useMemo, useState } from "react"

import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import { Timestamp } from "firebase/firestore"

import FiberNewIcon from "@mui/icons-material/FiberNew"
import {
    Card,
    CircularProgress,
    FormControl,
    InputAdornment,
    MenuItem,
    Modal,
    Paper,
    Select,
    TextField,
} from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import {
    DataGridPro,
    GridActionsCellItem,
    GridColDef,
    GridDeleteIcon,
    GridRowParams,
    GridSearchIcon,
    useGridApiRef,
} from "@mui/x-data-grid-pro"

import { addCard, getCards, removeCard, updateCard } from "./apis"
import { AreaOptions, CardStatusOptions } from "./config"
import { EditCardForm } from "./partials/EditCardForm"
import { formatCardNumber } from "./utils"

import type { Card as CardType } from "./model/types"

export default function CardPage() {
    const apiRef = useGridApiRef()
    const [openModal, setOpenModal] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState<CardType | null>(null)
    const [searchText, setSearchText] = useState("")
    const [searchField, setSearchField] = useState<"no" | "name" | "holder" | "issuer" | "inCharge">("no")

    const { data: cards } = useSuspenseQuery({ queryKey: ["cards"], queryFn: getCards })

    const addCardMutation = useMutation({
        mutationFn: addCard,
        onSuccess: (newCard) => {
            apiRef.current.updateRows([{ id: newCard.no, ...newCard }])
        },
        onError: (error) => {
            alert("카드 추가에 실패했습니다.\n")
        },
    })

    const updateCardMutation = useMutation({
        mutationFn: updateCard,
        onSuccess: (updatedCard) => {
            apiRef.current.updateRows([
                {
                    id: updatedCard.no,
                    ...updatedCard,
                },
            ])
        },
        onError: (error) => {
            alert("카드 수정에 실패했습니다.\n")
        },
    })

    const removeCardMutation = useMutation({
        mutationFn: removeCard,
        onSuccess: (data) => {
            apiRef.current.updateRows([{ id: data.no, _action: "delete" }])
        },
        onError: (error) => {
            alert("카드 삭제에 실패했습니다.\n")
        },
    })

    const handleOpenEditModal = (row?: CardType) => {
        setOpenModal(true)

        if (row) {
            setSelectedRowData(row)
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setSelectedRowData(null)
    }

    const handleSaveForm = async (formData: Omit<CardType, "id" | "createdAt" | "updatedAt">) => {
        if (selectedRowData) {
            await updateCardMutation.mutateAsync(formData)
        } else {
            const rowIds = [...apiRef.current.getRowModels().keys()]
            const isExistRowId = rowIds.some((id) => id === formData.no)
            if (isExistRowId) {
                alert("이미 존재하는 카드 번호입니다.")
                return
            }
            await addCardMutation.mutateAsync(formData)
        }

        setOpenModal(false)
        setSelectedRowData(null)
    }

    const rows = useMemo(() => {
        return cards.map((card) => ({
            id: card.no,
            ...card,
        }))
    }, [cards])

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: "no",
                headerName: "카드 번호",
                width: 180,
                valueFormatter: ({ value }) => formatCardNumber(value),
            },
            { field: "name", headerName: "카드 이름" },
            { field: "cvc", headerName: "CVC" },
            { field: "expire", headerName: "만료일" },
            {
                field: "status",
                headerName: "상태",
                valueFormatter: ({ value }) => {
                    const status = CardStatusOptions.find((o) => o.id === value)
                    return status?.label ?? value
                },
            },
            {
                field: "area",
                headerName: "지역",
                valueFormatter: ({ value }) => {
                    const area = AreaOptions.find((o) => o.id === value)
                    return area?.label ?? value
                },
            },
            { field: "type", headerName: "종류" },
            { field: "holder", headerName: "소유자" },
            { field: "holderId", headerName: "소유자 ID" },
            { field: "issuer", headerName: "발급자" },
            { field: "issuerId", headerName: "발급자 ID" },
            { field: "inCharge", headerName: "담당자" },
            { field: "inChargeId", headerName: "담당자 ID" },
            { field: "usage", headerName: "사용 용도", flex: 1 },
            { field: "significant", headerName: "특이사항", flex: 1 },
            { field: "memo", headerName: "메모", flex: 1 },
            {
                field: "createdAt",
                headerName: "생성일",
                valueFormatter: ({ value }) => {
                    return (value as Timestamp).toDate().toLocaleString()
                },
            },
            {
                field: "updatedAt",
                headerName: "수정일",
                valueFormatter: ({ value }) => {
                    return (value as Timestamp).toDate().toLocaleString()
                },
            },
            {
                field: "actions",
                type: "actions",
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        key="delete"
                        icon={<GridDeleteIcon />}
                        color="error"
                        onClick={async () => {
                            const isConfirm = window.confirm("정말 삭제하시겠습니까?")
                            if (!isConfirm) return
                            await removeCardMutation.mutateAsync(params.id as string)
                            apiRef.current.updateRows([{ id: params.id, _action: "delete" }])
                        }}
                        label="Delete"
                    />,
                ],
            },
        ]
    }, [])
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent
                            sx={{
                                minHeight: "100px",
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                <FormControl variant="outlined" sx={{ minWidth: 120, mr: 1 }}>
                                    <Select
                                        value={searchField}
                                        onChange={(e) =>
                                            setSearchField(
                                                e.target.value as "no" | "name" | "holder" | "issuer" | "inCharge"
                                            )
                                        }
                                        size="medium"
                                    >
                                        <MenuItem value="no">카드 번호</MenuItem>
                                        <MenuItem value="name">카드 이름</MenuItem>
                                        <MenuItem value="holder">소유자</MenuItem>
                                        <MenuItem value="issuer">발급자</MenuItem>
                                        <MenuItem value="inCharge">담당자</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder={"검색"}
                                    value={searchText}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        setSearchText(value)

                                        if (value) {
                                            apiRef.current.setFilterModel({
                                                items: [
                                                    {
                                                        field: searchField,
                                                        operator: "contains",
                                                        value: value,
                                                    },
                                                ],
                                            })
                                        } else {
                                            apiRef.current.setFilterModel({
                                                items: [],
                                            })
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <GridSearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <DataGridPro
                                apiRef={apiRef}
                                columns={columns}
                                rows={rows}
                                onRowClick={(params) => handleOpenEditModal(params.row)}
                                initialState={{
                                    filter: {
                                        filterModel: {
                                            items: [],
                                        },
                                    },
                                }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Box
                sx={{
                    position: "fixed",
                    display: "inline-flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    bottom: 0,
                    left: "50%",
                    transform: "translate(-50%, -1em)",
                    zIndex: 999,
                    backgroundColor: "white",
                    borderRadius: 5,
                    boxShadow: 2,
                    py: 2,
                    px: 3,
                }}
            >
                <Button
                    fullWidth
                    variant={"text"}
                    size={"large"}
                    startIcon={<FiberNewIcon />}
                    onClick={() => handleOpenEditModal()}
                >
                    새 카드
                </Button>
            </Box>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Paper
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        background: "white",
                        width: "90%",
                        maxHeight: "90%",
                        overflowY: "auto",
                        padding: 4,
                    }}
                    elevation={2}
                >
                    <Suspense
                        fallback={
                            <Box
                                sx={{
                                    height: "500px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        }
                    >
                        <EditCardForm
                            onSubmit={handleSaveForm}
                            onCancel={handleCloseModal}
                            defaultValues={selectedRowData ?? undefined}
                        />
                    </Suspense>
                </Paper>
            </Modal>
        </>
    )
}
