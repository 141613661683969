import { start } from "node:repl"

import { connectAuthEmulator, User } from "@firebase/auth"
import { initializeApp, getApps } from "firebase/app"
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut,
    onAuthStateChanged,
    setPersistence,
    browserLocalPersistence,
} from "firebase/auth"
import {
    getDatabase,
    ref,
    onValue,
    get,
    remove,
    set,
    update,
    push,
    query as queryRealtime,
    orderByChild,
    orderByValue,
    orderByKey,
    equalTo,
    limitToFirst,
    limitToLast,
    startAt,
    startAfter,
    endAt,
    endBefore,
    runTransaction,
    connectDatabaseEmulator,
} from "firebase/database"
import {
    getFirestore,
    collection,
    collectionGroup,
    query,
    onSnapshot,
    where,
    orderBy,
    doc,
    addDoc,
    setDoc,
    getDoc,
    getDocs,
    updateDoc,
    WhereFilterOp,
    DocumentSnapshot,
    QuerySnapshot,
    writeBatch,
    getCountFromServer,
    limit,
    startAt as startAtDoc,
    startAfter as startAfterDoc,
    Timestamp,
    serverTimestamp,
    connectFirestoreEmulator,
} from "firebase/firestore"
import { getFunctions, httpsCallable } from "firebase/functions"
import { getMessaging, getToken, onMessage, Unsubscribe, deleteToken } from "firebase/messaging"
import {
    connectStorageEmulator,
    getDownloadURL,
    getStorage,
    ref as storageRef,
    uploadBytesResumable,
} from "firebase/storage"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const apps = getApps()
const app = apps.length > 1 ? apps[0] : initializeApp(firebaseConfig)

// auth
const provider = new GoogleAuthProvider()
provider.addScope("https://www.googleapis.com/auth/contacts.readonly")
const auth = getAuth(app)
const firestore = getFirestore(app)
const db = getDatabase(app)
const storage = getStorage(app)
const functions = getFunctions(app)

if (process.env.REACT_APP_FIREBASE_ENV === "emulator") {
    // connectAuthEmulator(auth, "http://localhost:9002")
    // connectDatabaseEmulator(db, "localhost", 8002)
    connectFirestoreEmulator(firestore, "localhost", 8001)
}

export async function signInUser() {
    if (auth.currentUser) {
        return auth.currentUser
    }
    await setPersistence(auth, browserLocalPersistence)
    const result = await signInWithPopup(auth, provider)
    const credential = GoogleAuthProvider.credentialFromResult(result)
    const token = credential!.accessToken
    return result.user
}

export async function signOutUser() {
    return await signOut(auth)
}

export function getCurrentUser() {
    return auth.currentUser
}

export function onAuthStateChange(callback: (u: User | null) => void) {
    return onAuthStateChanged(auth, (user) => {
        if (user) {
            requestNotificationPermission()
        }
        callback(user)
    })
}

// firebase realtime database

export const database = getDatabase(app)

export function refWithBaseDatabase(path: string) {
    return ref(database, path)
}

export async function readRealtime<S>(path: string): Promise<S> {
    const snapshot = await get(refWithBaseDatabase(path))
    return snapshot.val() as S
}

export async function readWithPropValueStartAt<S>(
    path: string,
    prop: string,
    value: any
): Promise<{ [id: string]: S }> {
    const q = queryRealtime(refWithBaseDatabase(path), orderByChild(prop), startAt(value))
    const snapshot = await get(q)
    return snapshot.val() as { [id: string]: S }
}

export async function readWithPropValueStartEndAt<S>(
    path: string,
    prop: string,
    start: any,
    end: any
): Promise<{ [id: string]: S }> {
    const q = queryRealtime(refWithBaseDatabase(path), orderByChild(prop), startAt(start), endAt(end))
    const snapshot = await get(q)
    return snapshot.val() as { [id: string]: S }
}

export async function readWithKeyStartAt<S>(path: string, startKey: any): Promise<{ [id: string]: S }> {
    const q = queryRealtime(refWithBaseDatabase(path), orderByKey(), startAt(startKey))
    const snapshot = await get(q)
    return snapshot.val() as { [id: string]: S }
}

export async function readWithKeyStartEndAt<S>(path: string, startKey: any, endKey: any): Promise<{ [id: string]: S }> {
    const q = queryRealtime(refWithBaseDatabase(path), orderByKey(), startAt(startKey), endAt(endKey))
    const snapshot = await get(q)
    return snapshot.val() as { [id: string]: S }
}

export function listenRealtime<S>(path: string, callback: (s: S) => void, callbackError?: (error: Error) => void) {
    return onValue(
        refWithBaseDatabase(path),
        (snapshot) => {
            try {
                const s: S = snapshot.val()
                callback(s)
            } catch (e) {
                callbackError?.(e as Error)
            }
        },
        callbackError
    )
}

export async function removeRealtime(path: string) {
    log("realtime", "delete", path)
    return await remove(refWithBaseDatabase(path))
}

export async function updateRealtime(path: string, value: any) {
    log("realtime", "update", path, value)
    return await update(refWithBaseDatabase(path), value)
}

export async function setRealtime(path: string, value: any) {
    log("realtime", "write", path, value)
    return await set(refWithBaseDatabase(path), value)
}

export async function pushKeyRealtime(parent: string) {
    const snapshot = await push(refWithBaseDatabase(parent))
    return snapshot.key
}

export async function pushRealtime(parent: string, value: any) {
    const snapshot = await push(refWithBaseDatabase(parent), value)
    log("realtime", "write", parent, value)
    return snapshot.key
}

export function listenWithPropValue<S>(
    path: string,
    prop: string,
    value: any,
    callback: (s: { [id: string]: S }) => void,
    callbackError: (e: Error) => void
) {
    const q = queryRealtime(refWithBaseDatabase(path), orderByChild(prop), equalTo(value))
    return onValue(
        q,
        (snapshot) => {
            try {
                const s: { [id: string]: S } = snapshot.val()
                callback(s)
            } catch (e) {
                callbackError?.(e as Error)
            }
        },
        callbackError
    )
}

export function listenWithPropValueStartAt<S>(
    path: string,
    prop: string,
    value: any,
    callback: (s: { [id: string]: S }) => void,
    callbackError: (e: Error) => void
) {
    const q = queryRealtime(refWithBaseDatabase(path), orderByChild(prop), startAt(value))
    return onValue(
        q,
        (snapshot) => {
            try {
                const s: { [id: string]: S } = snapshot.val()
                callback(s)
            } catch (e) {
                callbackError?.(e as Error)
            }
        },
        callbackError
    )
}

export function listenWithPropValueEndAt<S>(
    path: string,
    prop: string,
    value: any,
    callback: (s: { [id: string]: S }) => void,
    callbackError: (e: Error) => void
) {
    const q = queryRealtime(refWithBaseDatabase(path), orderByChild(prop), endAt(value))
    return onValue(
        q,
        (snapshot) => {
            try {
                const s: { [id: string]: S } = snapshot.val()
                callback(s)
            } catch (e) {
                callbackError?.(e as Error)
            }
        },
        callbackError
    )
}

export function listenWithPropValueStartAfter<S>(
    path: string,
    prop: string,
    value: any,
    callback: (s: S[]) => void,
    callbackError: (e: Error) => void
) {
    const q = queryRealtime(refWithBaseDatabase(path), orderByChild(prop), startAfter(value))
    return onValue(
        q,
        (snapshot) => {
            try {
                const s: S[] = snapshot.val()
                callback(s)
            } catch (e) {
                callbackError?.(e as Error)
            }
        },
        callbackError
    )
}

export function docWithBaseFireStore(collection: string, id: string) {
    return doc(firestore, collection, id)
}

export function docWithBaseFireStoreMultiPath(paths: string[]) {
    const [path, ...pathSegments] = paths
    return doc(firestore, path, ...pathSegments)
}

export function collectionWithBaseFireStore(col: string) {
    return collection(firestore, col)
}

export function collectionWithBaseFireStoreMultiPath(paths: string[]) {
    const [path, ...pathSegments] = paths
    return collection(firestore, path, ...pathSegments)
}

export function collectionGroupWithBaseFireStore(path: string) {
    return collectionGroup(firestore, path)
}

export type Query = [string, string, string | number | boolean | string[] | Date | null | any]

export function queryWhereFireStore(col: string, queries: Query[]) {
    const wheres = queries.map(([property, operator, value]) => where(property, operator as WhereFilterOp, value))
    return query(collectionWithBaseFireStore(col), ...wheres)
}

export function queryWhereFireStoreGroup(col: string, queries: Query[]) {
    const wheres = queries.map(([property, operator, value]) => where(property, operator as WhereFilterOp, value))
    return query(collectionGroupWithBaseFireStore(col), ...wheres)
}

export function listenWhereFireStore(
    col: string,
    queries: Query[],
    onNext: (snapshot: QuerySnapshot<unknown>) => void,
    onError: (error: Error) => void = console.log
) {
    return onSnapshot(queryWhereFireStore(col, queries), onNext, onError)
}

export async function readFireStore<S>(collection: string, id: string): Promise<S> {
    const snapshot = await getDoc(docWithBaseFireStore(collection, id))
    return snapshot.data() as S
}

export async function queryFireStore<S>(collection: string, queries: Query[]) {
    const snapshot = await getDocs(queryWhereFireStore(collection, queries))
    const result: S[] = []
    if (snapshot.size === 0) return result
    snapshot.forEach((d) => {
        result.push({ id: d.id, ...d.data() } as S)
    })
    return result
}

export async function queryNestedFireStore<S>(collection: string, queries: Query[]) {
    const snapshot = await getDocs(queryWhereFireStoreGroup(collection, queries))
    return snapshot.docs.map((d) => d.data() as S)
}

export function queryNestedFireStoreDoc(
    collection: string,
    queries: Query[],
    onNext: (snapshot: QuerySnapshot<unknown>) => void,
    onError: (error: Error) => void = console.log
) {
    return onSnapshot(queryWhereFireStoreGroup(collection, queries), onNext, onError)
}

export async function paginateFireStore<S>(
    collection: string,
    option: {
        orderProp?: string
        whereQueries?: Query[]
        pageLimit: number
        page: number
    } = { pageLimit: 25, page: 0 }
) {
    const { orderProp, whereQueries, pageLimit, page } = option

    const wheres =
        whereQueries?.map(([property, operator, value]) => where(property, operator as WhereFilterOp, value)) ?? []
    const hasWhere = wheres.length > 0
    const hasOrderProp = !!orderProp

    const paginationQueries = []
    if (hasOrderProp) paginationQueries.push(orderBy(orderProp))
    if (hasWhere) paginationQueries.push(...wheres)

    const totalSnapshot = await getCountFromServer(query(collectionWithBaseFireStore(collection), ...paginationQueries))
    const total = totalSnapshot.data().count ?? 0

    const prevDocs = (
        await getDocs(query(collectionWithBaseFireStore(collection), ...paginationQueries, limit(pageLimit * page + 1)))
    ).docs
    const startDoc = prevDocs[prevDocs.length - 1]

    if (!startDoc)
        return {
            total,
            page,
            pageLimit,
            startWith: null,
            results: [],
        }

    if (startDoc) paginationQueries.push(startAtDoc(startDoc))
    if (pageLimit) paginationQueries.push(limit(pageLimit))

    const docs = (
        await getDocs(query(collectionWithBaseFireStore(collection), ...paginationQueries, limit(pageLimit * page + 1)))
    ).docs

    return {
        total,
        page,
        pageLimit,
        startWith: startDoc.data() as S,
        results: docs.map((d) => d.data() as S),
    }
}

export async function writeFirestore(paths: string[], data: any) {
    log("firestore", "write", paths.join("/"), data)
    await setDoc(docWithBaseFireStoreMultiPath(paths), data)
}

export async function updateFireStore(collection: string, id: string, updates: any) {
    log("firestore", "update", [collection, id].join("/"), updates)
    await updateDoc(docWithBaseFireStore(collection, id), updates)
}

export async function updateBatchFireStore(collection: string, ids: string[], updates: any) {
    const batch = writeBatch(firestore)
    log("firestore", "update", [collection, "${id}"].join("/"), updates, { ids, batch: true })
    ids.forEach((id) => {
        const ref = docWithBaseFireStore(collection, id)
        batch.update(ref, updates)
    })
    return batch.commit()
}

export async function callFunction<S>(name: string, data: unknown) {
    const callable = httpsCallable(functions, name, { timeout: 540000 })
    log("function", "call", name, data)
    const result = await callable(data)
    return result.data as S
}

async function requestNotificationPermission() {
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
        getFirebaseToken()
    } else {
        console.error("Notification permission denied.")
    }
}

const messaging = getMessaging(app)

export async function getFirebaseToken() {
    const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    })
    // window.prompt('copy', currentToken);
    if (getCurrentUser()?.uid) {
        //todo 목표 불명확한 token/ 인덱스
        setRealtime(`token/${currentToken}`, getCurrentUser()?.uid).catch(console.error)
        setRealtime(`user/${getCurrentUser()?.uid}/messageIds/${currentToken}`, Date.now()).catch(console.error)
    }
}

export async function removeUserToken() {
    await deleteToken(messaging)
}

export async function catchMessage(callback?: (message: string, chatId: string) => void): Promise<Unsubscribe | null> {
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
        return onMessage(messaging, (p) => {
            const { chatId, name, body } = p.data as {
                chatId: string
                name: string
                body: string
            }
            const regex = new RegExp(/^\/chat/)
            if (!regex.test(window.location.pathname)) {
                return callback?.(`${name}\n${body}`, chatId)
            }
            return null
        })
    }
    return null
}

export async function uploadFileToStorage(path: string, file: File) {
    return new Promise<string>((resolve, reject) => {
        const ref = storageRef(storage, path)
        const uploadTask = uploadBytesResumable(ref, file)
        uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
                reject(error)
            },
            async () => {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
                resolve(downloadUrl)
            }
        )
    })
}

export function log(
    service: "realtime" | "firestore" | "function",
    action: "call" | "write" | "update" | "delete",
    path: string,
    value?: any,
    others?: {
        [key: string]: any
    }
) {
    if (path.replace("/", "").startsWith("token")) return
    if (path.match(/user.+messageIds/)) return
    _log(service, action, path, value)
}

function _log(service: string, action: string, path: string, value?: any, others?: { [key: string]: any }) {
    const user = getCurrentUser()?.uid ?? "unknown"
    const strValue = JSON.stringify(value ?? "")
    const log = {
        user,
        service,
        action,
        path,
        value: strValue,
        others: others ?? {},
        timestamp: serverTimestamp(),
    }
    addDoc(collectionWithBaseFireStore("log"), log).catch((e) => {
        console.log("Cannot log", e)
    })
}

export type ACTION =
    | "CREATE RESERVATION"
    | "UPDATE RESERVATION"
    | "CANCEL RESERVATION"
    | "RECOVER RESERVATION"
    | "NOSHOW RESERVATION"
    | "RETRIEVE RESERVATION"
    | "DOWNLOAD RESERVATION"
    | "STAR RESERVATION"
    | "CHECK RESERVATION"
    | "CREATE PRODUCT"
    | "UPDATE PRODUCT"
    | "RECOVER PRODUCT"
    | "DELETE PRODUCT"
    | "DELETE EXCEPTION"
    | "CHANGE GUIDE"
    | "UPDATE TEAM MEMO"
    | "UPDATE OPERATION MEMO"
    | "UPDATE RESERVATIONS NOTE"
    | "CHANGE RESERVATION TEAM"
    | "CLEAR DISPATCH"
    | "UPDATE DISPATCH"
    | "UPDATE DISPATCH PHRASE"

export type ACTION_WHAT = string

export type ACTION_DOMAIN = "RESERVATION" | "PRODUCT" | "OPERATION" | "EXCEPTION"

export function logAction(
    domain: ACTION_DOMAIN,
    action: ACTION,
    what: ACTION_WHAT,
    detail: string,
    value?: any,
    others?: {
        [key: string]: any
    }
) {
    const user = getCurrentUser()?.uid ?? "unknown"
    const log = {
        user,
        domain,
        what,
        action,
        detail,
        value: value ?? null,
        others: others ?? {},
        timestamp: serverTimestamp(),
    }
    addDoc(collectionWithBaseFireStore("action"), log).catch((e) => {
        console.log("Cannot log action", e)
    })
}

export async function updateValueWithTransaction(path: string, updateFunction: (currentValue: any) => any) {
    const refPath = refWithBaseDatabase(path)

    try {
        await runTransaction(refPath, (currentData) => {
            const updatedData = updateFunction(currentData)
            return updatedData
        })
    } catch (error) {
        console.error("Transaction failed: ", error)
    }
}
