import { useCallback, useEffect, useState } from "react"

import { useSuspenseQuery } from "@tanstack/react-query"
import { z } from "zod"

import {
    Autocomplete,
    Button,
    createFilterOptions,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
} from "@mui/material"

import { readUsers } from "../apis"
import { AreaOptions, CardStatusOptions } from "../config"
import { Card, CardFormInput, Option } from "../model/types"
import { validateCardForm } from "../model/validate"
import { formatCardNumber, formatExpire, formatOnlyDigits } from "../utils"

interface EditCardFormProps {
    defaultValues?: Card
    onSubmit?: (formData: CardFormInput) => void
    onCancel?: () => void
}

type FormValues = Omit<
    CardFormInput,
    "area" | "holder" | "holderId" | "issuer" | "issuerId" | "inCharge" | "inChargeId"
>

export const EditCardForm = (props: EditCardFormProps) => {
    const { defaultValues, onSubmit, onCancel } = props

    const { data: usersOptions } = useSuspenseQuery({
        queryKey: ["user"],
        queryFn: readUsers,
        staleTime: Infinity,
        select: (data) => {
            const set = new Set<string>()
            Object.values(data).forEach((u) => {
                if (u.id && u.name) set.add(u.id)
            })
            const unique = Array.from(set).map((id) => ({ id, label: data[id].name }))
            return unique
        },
    })

    const [formValues, setFormValues] = useState<FormValues>({
        name: defaultValues?.name ?? "",
        no: defaultValues?.no ? formatCardNumber(defaultValues.no) : "",
        expire: defaultValues?.expire ?? "",
        cvc: defaultValues?.cvc ?? "",
        status: defaultValues?.status ?? "use",
        type: defaultValues?.type ?? "",
        usage: defaultValues?.usage ?? "",
        significant: defaultValues?.significant ?? "",
        memo: defaultValues?.memo ?? "",
    })

    const [errors, setErrors] = useState<z.ZodError["errors"] | undefined>(undefined)
    const [area, setArea] = useState<Option | null>(
        defaultValues?.area ? AreaOptions.find((o) => o.id === defaultValues.area)! : { id: "seoul", label: "서울" }
    )
    const [holder, setHolder] = useState<Option | null>(
        defaultValues?.holder ? { id: defaultValues.holderId, label: defaultValues.holder } : usersOptions[0]
    )
    const [issuer, setIssuer] = useState<Option | null>(
        defaultValues?.issuer ? { id: defaultValues.issuerId, label: defaultValues.issuer } : usersOptions[0]
    )
    const [inCharge, setInCharge] = useState<Option | null>(
        defaultValues?.inCharge ? { id: defaultValues.inChargeId, label: defaultValues.inCharge } : usersOptions[0]
    )

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
            const { name, value } = e.target

            let parsedValue = name === "memo" || name === "significant" || name === "usage" ? value : value.trim()
            switch (name) {
                case "no": {
                    parsedValue = formatCardNumber(value)
                    break
                }
                case "expire": {
                    parsedValue = formatExpire(value)
                    break
                }
                case "cvc": {
                    parsedValue = formatOnlyDigits(value)
                    break
                }
            }
            setFormValues((prev) => ({ ...prev, [name]: parsedValue }))
        },
        []
    )

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()

            const combinedData = {
                ...formValues,
                no: formValues.no.replace(/\s/g, ""),
                area: area?.id,
                holder: holder?.label,
                holderId: holder?.id,
                issuer: issuer?.label,
                issuerId: issuer?.id,
                inCharge: inCharge?.label,
                inChargeId: inCharge?.id,
            }

            const result = validateCardForm(combinedData)
            if (result.success && result.data) {
                onSubmit?.(result.data)
            }

            setErrors(result.error?.errors)
        },
        [formValues, area, holder, issuer, inCharge]
    )

    const isError = (name: keyof CardFormInput) => {
        return Boolean(errors?.find((e) => e.path.includes(name)))
    }

    const getErrorMsg = (name: keyof CardFormInput) => {
        return errors?.find((e) => e.path.includes(name))?.message ?? ""
    }

    useEffect(() => {
        const combinedData = {
            ...formValues,
            no: formValues.no.replace(/\s/g, ""),
            area: area?.id,
            holder: holder?.label,
            holderId: holder?.id,
            issuer: issuer?.label,
            issuerId: issuer?.id,
            inCharge: inCharge?.label,
            inChargeId: inCharge?.id,
        }
        const result = validateCardForm(combinedData)

        setErrors(result.error?.errors)
    }, [formValues, area, holder, issuer, inCharge])

    return (
        <Grid container spacing={2} component={"form"} onSubmit={handleSubmit}>
            <Grid item xs={4}>
                <Typography variant={"h6"}>카드 기본 정보</Typography>
            </Grid>
            <Grid item xs={8}>
                <Stack spacing={2}>
                    <TextField
                        name="no"
                        label="카드 번호"
                        fullWidth
                        required
                        value={formValues.no}
                        error={isError("no")}
                        helperText={getErrorMsg("no")}
                        onChange={handleInputChange}
                        disabled={defaultValues?.no !== undefined}
                    />
                    <TextField
                        name="name"
                        label="카드 이름"
                        fullWidth
                        required
                        value={formValues.name}
                        error={isError("name")}
                        helperText={getErrorMsg("name")}
                        onChange={handleInputChange}
                    />
                    <TextField
                        name="expire"
                        label="만료일"
                        fullWidth
                        required
                        value={formValues.expire}
                        error={isError("expire")}
                        helperText={getErrorMsg("expire")}
                        onChange={handleInputChange}
                    />
                    <TextField
                        name="cvc"
                        label="CVC"
                        fullWidth
                        required
                        value={formValues.cvc}
                        error={isError("cvc")}
                        helperText={getErrorMsg("cvc")}
                        onChange={handleInputChange}
                    />
                </Stack>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                <Divider />
            </Grid>

            <Grid item xs={4}>
                <Typography variant={"h6"}>카드 상세 정보</Typography>
            </Grid>
            <Grid item xs={8}>
                <Stack spacing={2}>
                    <FormControl fullWidth required>
                        <InputLabel id="status-label">상태</InputLabel>
                        <Select
                            labelId="status-label"
                            name="status"
                            onChange={handleInputChange}
                            label="상태"
                            value={formValues.status}
                        >
                            {CardStatusOptions.map((o) => (
                                <MenuItem value={o.id} key={o.id}>
                                    {o.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Autocomplete
                        value={area}
                        onChange={(_, newValue) => setArea(newValue)}
                        options={AreaOptions}
                        isOptionEqualToValue={(option, value) => {
                            const isExist = AreaOptions.find((o) => o.id === value?.id)
                            if (isExist) return option.id === value.id
                            return true
                        }}
                        filterOptions={(options, params) => {
                            const filter = createFilterOptions<Option>()
                            const filtered = filter(options, params)

                            const { inputValue } = params
                            const isExisting = options.some((option) => inputValue === option.label)
                            if (inputValue !== "" && !isExisting) {
                                filtered.push({
                                    id: inputValue.toLowerCase(),
                                    label: inputValue,
                                })
                            }

                            return filtered
                        }}
                        getOptionLabel={(option) => {
                            return option.label
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="지역"
                                required
                                error={isError("area")}
                                helperText={getErrorMsg("area")}
                            />
                        )}
                        renderOption={(props, option) => {
                            const { ...optionProps } = props
                            return (
                                <li {...optionProps} key={optionProps.id}>
                                    {option.label}
                                </li>
                            )
                        }}
                    />
                    <TextField
                        name="type"
                        label="종류"
                        value={formValues.type}
                        fullWidth
                        required
                        error={isError("type")}
                        helperText={getErrorMsg("type")}
                        onChange={handleInputChange}
                    />
                    <TextField
                        name="usage"
                        label="사용 용도"
                        value={formValues.usage}
                        fullWidth
                        required
                        error={isError("usage")}
                        helperText={getErrorMsg("usage")}
                        onChange={handleInputChange}
                    />
                </Stack>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                <Divider />
            </Grid>

            <Grid item xs={4}>
                <Typography variant={"h6"}>카드 관리자</Typography>
            </Grid>
            <Grid item xs={8}>
                <Stack spacing={2}>
                    <Stack direction="row" spacing={1}>
                        <Autocomplete
                            fullWidth
                            options={usersOptions}
                            value={holder}
                            onChange={(_, newValue) => {
                                setHolder(newValue)
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="holder"
                                    label="소유자"
                                    required
                                    error={isError("holder")}
                                    helperText={getErrorMsg("holder")}
                                />
                            )}
                            renderOption={(props, option) => {
                                const { ...optionProps } = props
                                return (
                                    <li {...optionProps} key={optionProps.id}>
                                        {option.label}
                                    </li>
                                )
                            }}
                        />
                        <TextField fullWidth disabled value={holder?.id ?? ""} />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Autocomplete
                            fullWidth
                            options={usersOptions}
                            value={issuer}
                            onChange={(_, newValue) => setIssuer(newValue)}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="발행자"
                                    required
                                    error={isError("issuer")}
                                    helperText={getErrorMsg("issuer")}
                                />
                            )}
                            renderOption={(props, option) => {
                                const { ...optionProps } = props
                                return (
                                    <li {...optionProps} key={optionProps.id}>
                                        {option.label}
                                    </li>
                                )
                            }}
                        />
                        <TextField fullWidth disabled value={issuer?.id ?? ""} />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Autocomplete
                            fullWidth
                            options={usersOptions}
                            value={inCharge}
                            onChange={(_, newValue) => setInCharge(newValue)}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="담당자"
                                    required
                                    error={isError("inCharge")}
                                    helperText={getErrorMsg("inCharge")}
                                />
                            )}
                            renderOption={(props, option) => {
                                const { ...optionProps } = props
                                return (
                                    <li {...optionProps} key={optionProps.id}>
                                        {option.label}
                                    </li>
                                )
                            }}
                        />
                        <TextField fullWidth disabled value={inCharge?.id ?? ""} />
                    </Stack>
                </Stack>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                <Divider />
            </Grid>

            <Grid item xs={4}>
                <Typography variant={"h6"}>관리 정보</Typography>
            </Grid>
            <Grid item xs={8}>
                <Stack spacing={2}>
                    <TextField
                        name="significant"
                        label="중요 정보"
                        multiline
                        value={formValues.significant}
                        fullWidth
                        rows={8}
                        onChange={handleInputChange}
                    />
                    <TextField
                        name="memo"
                        label="메모"
                        value={formValues.memo}
                        multiline
                        fullWidth
                        rows={8}
                        onChange={handleInputChange}
                    />
                </Stack>
            </Grid>

            <Grid item xs={6}>
                <Button variant="contained" color="error" fullWidth onClick={onCancel}>
                    취소
                </Button>
            </Grid>

            <Grid item xs={6}>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    저장
                </Button>
            </Grid>
        </Grid>
    )
}
