import { Option } from "../model/types"

export const AreaOptions: Option[] = [
    {
        id: "seoul",
        label: "서울",
    },
    {
        id: "busan",
        label: "부산",
    },
    {
        id: "tokyo",
        label: "도쿄",
    },
    {
        id: "osaka",
        label: "오사카",
    },
    {
        id: "overseas",
        label: "해외",
    },
]

export const CardStatusOptions: Option[] = [
    {
        id: "use",
        label: "이용",
    },
    {
        id: "lost",
        label: "분실",
    },
    {
        id: "storage",
        label: "보관",
    },
    {
        id: "cancel",
        label: "해지",
    },
]
