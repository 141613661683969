import * as XLSX from "xlsx";
import AgencyRow, {RawAgencyRow} from "./agency";


export default async function parseKlookFile(file: File | Blob): Promise<AgencyRow[]> {
  const parsed = await parseFileData(file);
  const aggregation = parsed.reduce((result, row) => {
    const agency = 'L';
    const agencyCode = cleanString(row['Booking Reference ID']);
    const clientName = cleanString(row['Traveler Name']);
    const product = cleanString(row['Activity Name'] + row['Package Name']);
    const number = toNumber(row['Num of Units']);
    const date = cleanString(row['Participation Time'].split(' ')[0]);
    const extraInfo = (cleanString(row['Extra Info']) ?? '').split('\n').map(info => info.split(': ')).reduce((result, info) => {
      result[info[0]] = cleanString(info[1]);
      return result;
    }, {} as { [property: string]: string });
    const language = extraInfo['Preferred language']?.toLowerCase().replace('english', 'EN').replace('chinese', 'CN').replace('korean', 'KO');
    const pickupPlace = cleanString(extraInfo['Pick-up location'] ?? extraInfo['Pick-up time'] ?? extraInfo['Pick-up Location & Time'] ?? extraInfo['Departing from'] ?? extraInfo['Departure location'] ?? extraInfo['Boarding Point'] ?? extraInfo['Meet Up Location'] ?? '-');
    const stroller = cleanString(
      extraInfo['Will you be bringing a baby stroller or wheelchair?']
      ?? extraInfo['Are your be bringing a baby stroller or wheelchair?']
      ?? extraInfo['Will you bring wheelchair or baby strollers?']
      ?? extraInfo['Will you be bringing a baby stroller?']
      ?? extraInfo['Will you bring a baby stroller or wheelchair?']
      ?? '').toLowerCase() === 'yes' ? 'O' : 'X';

    const option = cleanString(row['Package Name']);
    const railbikeOption =
      extraInfo['Railbike Preference']?.toLowerCase().includes('private') || extraInfo['Railbike preference']?.toLowerCase().includes('private')
        ? 'PV BIKE'
        : extraInfo['Railbike Preference']?.toLowerCase().includes('shared') || extraInfo['Railbike preference']?.toLowerCase().includes('shared')
          ? undefined
          : extraInfo['Railbike Preference'];
    const spalandOption =
      extraInfo['SPA LAND Preference']?.toLowerCase().includes('yes') || extraInfo['SPA LAND preference']?.toLowerCase().includes('yes')
        ? 'SPA LAND'
        : undefined;
    const returnOption = cleanString(extraInfo['Preferred return option']);
    const yachtOption = cleanString(extraInfo['Yacht Tour or Free time at Dongbaek island']);
    const desertOption = cleanString(extraInfo['Dessert Preference']);
    let unit = cleanString(row['Unit name']);
    if (
      /(\d+) x \[Add-on\] Train → Capsule/i.exec(unit)
      || /(\d+) x Person \[Sky Capsule\]/i.exec(unit)
    ) {
      unit = 'CAPSULE';
    }

    const options = [desertOption, yachtOption, returnOption, railbikeOption, spalandOption, option].filter(o => !!o);
    const obj: RawAgencyRow = agencyCode in result
      ? result[agencyCode]
      : {
        agency,
        agencyCode,
        clientName,
        language,
        product,
        date,
        pickupPlace,
        stroller,
        options: options.map(option => ({option, people: 0})),
        people: 0,
      };

    if (
      (unit.toLowerCase().includes('person') && !unit.toLowerCase().includes('per person'))
      || unit.toLowerCase().includes('person(3 years old▲)')
      || unit.toLowerCase().includes('adult')
      || unit.toLowerCase().includes('child')
    ) {
      obj.people += number;
      obj.options = obj.options.map((o) =>
        o.option === option || options.includes(o.option) ? {
          option: o.option,
          people: o.people + number
        } : o)
    } else {
      const option = {option: unit, people: number}
      obj.options.push(option);
    }
    result[agencyCode] = obj;
    return result;
  }, {} as {
    [agencyCode: string]: RawAgencyRow
  });
  return Object.values(aggregation);
}

const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array"})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet) as any[];
  return data;
  // return data.map((row, idx, array) => {
  //   return row;
  //   if (row['Booking Reference ID']) {
  //     return row;
  //   }
  //   let lastValid = null;
  //   for (let i = 1; i < idx + 1 && (!lastValid || !lastValid['Booking Reference ID']); i++) {
  //     lastValid = array[idx - i];
  //   }
  //   return {...lastValid, ...pickValues(row, ['Unit name', 'Num of Units',])};
  // });
}

// function pickValues<T extends Object>(obj: T, properties: string[]): Partial<T> {
//   return Object.fromEntries(Object.entries(obj).filter(([property]) => properties.includes(property))) as Partial<T>;
// }

function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}

