import { get } from "firebase/database"
import { deleteDoc, getDocs, setDoc, Timestamp, updateDoc } from "firebase/firestore"

import { refWithBaseDatabase, docWithBaseFireStore, collectionWithBaseFireStore } from "../../../hooks/firebase"
import { User } from "../../../models/User"
import { Card, CardFormInput } from "../model/types"

export const getCards = async () => {
    const ref = collectionWithBaseFireStore("cards")
    const resp = await getDocs(ref)
    return resp.docs.map((d) => d.data() as Card)
}

export const addCard = async (card: CardFormInput) => {
    const data = {
        ...card,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
    }

    const ref = docWithBaseFireStore("cards", card.no)
    await setDoc(ref, data)

    return data
}

export const updateCard = async (card: CardFormInput) => {
    const data = {
        ...card,
        updatedAt: Timestamp.now(),
    }
    const ref = docWithBaseFireStore("cards", card.no)
    await updateDoc(ref, data)

    return data
}

export const readUsers = async () => {
    const snapshot = await get(refWithBaseDatabase("user"))

    return snapshot.val() as Record<string, User>
}

export const removeCard = async (cardNo: string) => {
    const ref = docWithBaseFireStore("cards", cardNo)
    await deleteDoc(ref)
    return { no: cardNo }
}
