import { z } from "zod"

const StatusEnum = z.enum(["use", "lost", "storage", "cancel"], {
    required_error: "상태는 필수입니다",
})
const AreaEnum = z
    .enum(["seoul", "busan", "tokyo", "osaka", "overseas"], {
        required_error: "지역은 필수입니다",
    })
    .or(
        z.string({
            required_error: "지역은 필수입니다",
        })
    )

export const CardSchema = z.object({
    no: z.string().length(16, "카드번호의 길이는 16자여야 합니다.").regex(/[0-9]/g, "카드번호는 숫자만 입력해주세요"),
    name: z.string().min(1, "카드 이름을 입력해주세요"),
    cvc: z.string().regex(/^[0-9]{3}$/, "CVC는 숫자만 입력해주세요. 예시: 123"),
    expire: z
        .string()
        .regex(/^\d{2}\/\d{2}$/, "만료일은 MM/YY 형식으로 입력해주세요")
        .refine(
            (value) => {
                const month = parseInt(value.split("/")[0], 10)
                return month >= 1 && month <= 12
            },
            { message: "월은 01~12 사이의 값이어야 합니다" }
        ),
    status: StatusEnum,
    area: AreaEnum,
    type: z.string().min(1, "종류를 입력해주세요"),
    usage: z.string().min(1, "사용 용도를 입력해주세요"),
    holder: z.string().min(1, "소유자를 입력해주세요"),
    holderId: z.string(),
    issuer: z.string().min(1, "발행자를 입력해주세요"),
    issuerId: z.string(),
    inCharge: z.string().min(1, "담당자를 입력해주세요"),
    inChargeId: z.string(),
    significant: z.string().optional(),
    memo: z.string().optional(),
})
