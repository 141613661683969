export const formatCardNumber = (value: string): string => {
    const cleaned = value.replace(/[^\d\s]/g, "")
    const digitsOnly = cleaned.replace(/\s/g, "")

    let formatted = ""
    for (let i = 0; i < digitsOnly.length; i++) {
        if (i > 0 && i % 4 === 0) {
            formatted += " "
        }
        formatted += digitsOnly[i]
    }

    return formatted
}

export const formatOnlyDigits = (value: string) => {
    return value.replace(/[^\d]/g, "")
}

export const formatExpire = (value: string, format = "/") => {
    const digitsOnly = value.trim().replace(/[^\d]/g, "")
    const month = digitsOnly.substring(0, 2)
    const year = digitsOnly.substring(2, 4)

    if (year.length === 0) return month

    return `${month}${format}${year}`
}
