import { z } from "zod"

import { CardSchema } from "./scheme"

export const validateCardForm = (data: unknown) => {
    try {
        const validatedData = CardSchema.parse(data)
        return { success: true, data: validatedData }
    } catch (error) {
        if (error instanceof z.ZodError) {
            return { success: false, error }
        }
        throw error
    }
}
